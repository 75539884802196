// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-arrow-down-lg-inverse-name: 'icon-arrow-down-lg-inverse';
$icon-arrow-down-lg-inverse-x: 90px;
$icon-arrow-down-lg-inverse-y: 159px;
$icon-arrow-down-lg-inverse-offset-x: -90px;
$icon-arrow-down-lg-inverse-offset-y: -159px;
$icon-arrow-down-lg-inverse-width: 42px;
$icon-arrow-down-lg-inverse-height: 22px;
$icon-arrow-down-lg-inverse-total-width: 221px;
$icon-arrow-down-lg-inverse-total-height: 191px;
$icon-arrow-down-lg-inverse-image: '../img/sprite.png';
$icon-arrow-down-lg-inverse: (90px, 159px, -90px, -159px, 42px, 22px, 221px, 191px, '../img/sprite.png', 'icon-arrow-down-lg-inverse', );
$icon-arrow-down-name: 'icon-arrow-down';
$icon-arrow-down-x: 201px;
$icon-arrow-down-y: 154px;
$icon-arrow-down-offset-x: -201px;
$icon-arrow-down-offset-y: -154px;
$icon-arrow-down-width: 16px;
$icon-arrow-down-height: 9px;
$icon-arrow-down-total-width: 221px;
$icon-arrow-down-total-height: 191px;
$icon-arrow-down-image: '../img/sprite.png';
$icon-arrow-down: (201px, 154px, -201px, -154px, 16px, 9px, 221px, 191px, '../img/sprite.png', 'icon-arrow-down', );
$icon-arrow-left-inverse-name: 'icon-arrow-left-inverse';
$icon-arrow-left-inverse-x: 201px;
$icon-arrow-left-inverse-y: 114px;
$icon-arrow-left-inverse-offset-x: -201px;
$icon-arrow-left-inverse-offset-y: -114px;
$icon-arrow-left-inverse-width: 9px;
$icon-arrow-left-inverse-height: 16px;
$icon-arrow-left-inverse-total-width: 221px;
$icon-arrow-left-inverse-total-height: 191px;
$icon-arrow-left-inverse-image: '../img/sprite.png';
$icon-arrow-left-inverse: (201px, 114px, -201px, -114px, 9px, 16px, 221px, 191px, '../img/sprite.png', 'icon-arrow-left-inverse', );
$icon-arrow-left-lg-inverse-name: 'icon-arrow-left-lg-inverse';
$icon-arrow-left-lg-inverse-x: 0px;
$icon-arrow-left-lg-inverse-y: 69px;
$icon-arrow-left-lg-inverse-offset-x: 0px;
$icon-arrow-left-lg-inverse-offset-y: -69px;
$icon-arrow-left-lg-inverse-width: 44px;
$icon-arrow-left-lg-inverse-height: 86px;
$icon-arrow-left-lg-inverse-total-width: 221px;
$icon-arrow-left-lg-inverse-total-height: 191px;
$icon-arrow-left-lg-inverse-image: '../img/sprite.png';
$icon-arrow-left-lg-inverse: (0px, 69px, 0px, -69px, 44px, 86px, 221px, 191px, '../img/sprite.png', 'icon-arrow-left-lg-inverse', );
$icon-arrow-left-name: 'icon-arrow-left';
$icon-arrow-left-x: 201px;
$icon-arrow-left-y: 167px;
$icon-arrow-left-offset-x: -201px;
$icon-arrow-left-offset-y: -167px;
$icon-arrow-left-width: 9px;
$icon-arrow-left-height: 16px;
$icon-arrow-left-total-width: 221px;
$icon-arrow-left-total-height: 191px;
$icon-arrow-left-image: '../img/sprite.png';
$icon-arrow-left: (201px, 167px, -201px, -167px, 9px, 16px, 221px, 191px, '../img/sprite.png', 'icon-arrow-left', );
$icon-arrow-right-inverse-name: 'icon-arrow-right-inverse';
$icon-arrow-right-inverse-x: 201px;
$icon-arrow-right-inverse-y: 94px;
$icon-arrow-right-inverse-offset-x: -201px;
$icon-arrow-right-inverse-offset-y: -94px;
$icon-arrow-right-inverse-width: 9px;
$icon-arrow-right-inverse-height: 16px;
$icon-arrow-right-inverse-total-width: 221px;
$icon-arrow-right-inverse-total-height: 191px;
$icon-arrow-right-inverse-image: '../img/sprite.png';
$icon-arrow-right-inverse: (201px, 94px, -201px, -94px, 9px, 16px, 221px, 191px, '../img/sprite.png', 'icon-arrow-right-inverse', );
$icon-arrow-right-lg-inverse-name: 'icon-arrow-right-lg-inverse';
$icon-arrow-right-lg-inverse-x: 48px;
$icon-arrow-right-lg-inverse-y: 69px;
$icon-arrow-right-lg-inverse-offset-x: -48px;
$icon-arrow-right-lg-inverse-offset-y: -69px;
$icon-arrow-right-lg-inverse-width: 44px;
$icon-arrow-right-lg-inverse-height: 86px;
$icon-arrow-right-lg-inverse-total-width: 221px;
$icon-arrow-right-lg-inverse-total-height: 191px;
$icon-arrow-right-lg-inverse-image: '../img/sprite.png';
$icon-arrow-right-lg-inverse: (48px, 69px, -48px, -69px, 44px, 86px, 221px, 191px, '../img/sprite.png', 'icon-arrow-right-lg-inverse', );
$icon-arrow-right-sm-primary-name: 'icon-arrow-right-sm-primary';
$icon-arrow-right-sm-primary-x: 214px;
$icon-arrow-right-sm-primary-y: 70px;
$icon-arrow-right-sm-primary-offset-x: -214px;
$icon-arrow-right-sm-primary-offset-y: -70px;
$icon-arrow-right-sm-primary-width: 5px;
$icon-arrow-right-sm-primary-height: 8px;
$icon-arrow-right-sm-primary-total-width: 221px;
$icon-arrow-right-sm-primary-total-height: 191px;
$icon-arrow-right-sm-primary-image: '../img/sprite.png';
$icon-arrow-right-sm-primary: (214px, 70px, -214px, -70px, 5px, 8px, 221px, 191px, '../img/sprite.png', 'icon-arrow-right-sm-primary', );
$icon-arrow-right-sm-name: 'icon-arrow-right-sm';
$icon-arrow-right-sm-x: 214px;
$icon-arrow-right-sm-y: 82px;
$icon-arrow-right-sm-offset-x: -214px;
$icon-arrow-right-sm-offset-y: -82px;
$icon-arrow-right-sm-width: 5px;
$icon-arrow-right-sm-height: 8px;
$icon-arrow-right-sm-total-width: 221px;
$icon-arrow-right-sm-total-height: 191px;
$icon-arrow-right-sm-image: '../img/sprite.png';
$icon-arrow-right-sm: (214px, 82px, -214px, -82px, 5px, 8px, 221px, 191px, '../img/sprite.png', 'icon-arrow-right-sm', );
$icon-arrow-right-name: 'icon-arrow-right';
$icon-arrow-right-x: 201px;
$icon-arrow-right-y: 134px;
$icon-arrow-right-offset-x: -201px;
$icon-arrow-right-offset-y: -134px;
$icon-arrow-right-width: 9px;
$icon-arrow-right-height: 16px;
$icon-arrow-right-total-width: 221px;
$icon-arrow-right-total-height: 191px;
$icon-arrow-right-image: '../img/sprite.png';
$icon-arrow-right: (201px, 134px, -201px, -134px, 9px, 16px, 221px, 191px, '../img/sprite.png', 'icon-arrow-right', );
$icon-arrow-up-inverse-name: 'icon-arrow-up-inverse';
$icon-arrow-up-inverse-x: 177px;
$icon-arrow-up-inverse-y: 93px;
$icon-arrow-up-inverse-offset-x: -177px;
$icon-arrow-up-inverse-offset-y: -93px;
$icon-arrow-up-inverse-width: 12px;
$icon-arrow-up-inverse-height: 7px;
$icon-arrow-up-inverse-total-width: 221px;
$icon-arrow-up-inverse-total-height: 191px;
$icon-arrow-up-inverse-image: '../img/sprite.png';
$icon-arrow-up-inverse: (177px, 93px, -177px, -93px, 12px, 7px, 221px, 191px, '../img/sprite.png', 'icon-arrow-up-inverse', );
$icon-close-inverse-name: 'icon-close-inverse';
$icon-close-inverse-x: 177px;
$icon-close-inverse-y: 69px;
$icon-close-inverse-offset-x: -177px;
$icon-close-inverse-offset-y: -69px;
$icon-close-inverse-width: 20px;
$icon-close-inverse-height: 20px;
$icon-close-inverse-total-width: 221px;
$icon-close-inverse-total-height: 191px;
$icon-close-inverse-image: '../img/sprite.png';
$icon-close-inverse: (177px, 69px, -177px, -69px, 20px, 20px, 221px, 191px, '../img/sprite.png', 'icon-close-inverse', );
$icon-close-name: 'icon-close';
$icon-close-x: 177px;
$icon-close-y: 106px;
$icon-close-offset-x: -177px;
$icon-close-offset-y: -106px;
$icon-close-width: 20px;
$icon-close-height: 20px;
$icon-close-total-width: 221px;
$icon-close-total-height: 191px;
$icon-close-image: '../img/sprite.png';
$icon-close: (177px, 106px, -177px, -106px, 20px, 20px, 221px, 191px, '../img/sprite.png', 'icon-close', );
$icon-download-inverse-name: 'icon-download-inverse';
$icon-download-inverse-x: 136px;
$icon-download-inverse-y: 159px;
$icon-download-inverse-offset-x: -136px;
$icon-download-inverse-offset-y: -159px;
$icon-download-inverse-width: 21px;
$icon-download-inverse-height: 22px;
$icon-download-inverse-total-width: 221px;
$icon-download-inverse-total-height: 191px;
$icon-download-inverse-image: '../img/sprite.png';
$icon-download-inverse: (136px, 159px, -136px, -159px, 21px, 22px, 221px, 191px, '../img/sprite.png', 'icon-download-inverse', );
$icon-energy-name: 'icon-energy';
$icon-energy-x: 96px;
$icon-energy-y: 105px;
$icon-energy-offset-x: -96px;
$icon-energy-offset-y: -105px;
$icon-energy-width: 24px;
$icon-energy-height: 39px;
$icon-energy-total-width: 221px;
$icon-energy-total-height: 191px;
$icon-energy-image: '../img/sprite.png';
$icon-energy: (96px, 105px, -96px, -105px, 24px, 39px, 221px, 191px, '../img/sprite.png', 'icon-energy', );
$icon-facebook-inverse-name: 'icon-facebook-inverse';
$icon-facebook-inverse-x: 201px;
$icon-facebook-inverse-y: 70px;
$icon-facebook-inverse-offset-x: -201px;
$icon-facebook-inverse-offset-y: -70px;
$icon-facebook-inverse-width: 9px;
$icon-facebook-inverse-height: 20px;
$icon-facebook-inverse-total-width: 221px;
$icon-facebook-inverse-total-height: 191px;
$icon-facebook-inverse-image: '../img/sprite.png';
$icon-facebook-inverse: (201px, 70px, -201px, -70px, 9px, 20px, 221px, 191px, '../img/sprite.png', 'icon-facebook-inverse', );
$icon-fan-name: 'icon-fan';
$icon-fan-x: 140px;
$icon-fan-y: 106px;
$icon-fan-offset-x: -140px;
$icon-fan-offset-y: -106px;
$icon-fan-width: 33px;
$icon-fan-height: 33px;
$icon-fan-total-width: 221px;
$icon-fan-total-height: 191px;
$icon-fan-image: '../img/sprite.png';
$icon-fan: (140px, 106px, -140px, -106px, 33px, 33px, 221px, 191px, '../img/sprite.png', 'icon-fan', );
$icon-gplus-inverse-name: 'icon-gplus-inverse';
$icon-gplus-inverse-x: 201px;
$icon-gplus-inverse-y: 0px;
$icon-gplus-inverse-offset-x: -201px;
$icon-gplus-inverse-offset-y: 0px;
$icon-gplus-inverse-width: 20px;
$icon-gplus-inverse-height: 20px;
$icon-gplus-inverse-total-width: 221px;
$icon-gplus-inverse-total-height: 191px;
$icon-gplus-inverse-image: '../img/sprite.png';
$icon-gplus-inverse: (201px, 0px, -201px, 0px, 20px, 20px, 221px, 191px, '../img/sprite.png', 'icon-gplus-inverse', );
$icon-linkedin-inverse-name: 'icon-linkedin-inverse';
$icon-linkedin-inverse-x: 161px;
$icon-linkedin-inverse-y: 159px;
$icon-linkedin-inverse-offset-x: -161px;
$icon-linkedin-inverse-offset-y: -159px;
$icon-linkedin-inverse-width: 20px;
$icon-linkedin-inverse-height: 20px;
$icon-linkedin-inverse-total-width: 221px;
$icon-linkedin-inverse-total-height: 191px;
$icon-linkedin-inverse-image: '../img/sprite.png';
$icon-linkedin-inverse: (161px, 159px, -161px, -159px, 20px, 20px, 221px, 191px, '../img/sprite.png', 'icon-linkedin-inverse', );
$icon-logo-name: 'icon-logo';
$icon-logo-x: 0px;
$icon-logo-y: 159px;
$icon-logo-offset-x: 0px;
$icon-logo-offset-y: -159px;
$icon-logo-width: 86px;
$icon-logo-height: 32px;
$icon-logo-total-width: 221px;
$icon-logo-total-height: 191px;
$icon-logo-image: '../img/sprite.png';
$icon-logo: (0px, 159px, 0px, -159px, 86px, 32px, 221px, 191px, '../img/sprite.png', 'icon-logo', );
$icon-map-name: 'icon-map';
$icon-map-x: 71px;
$icon-map-y: 0px;
$icon-map-offset-x: -71px;
$icon-map-offset-y: 0px;
$icon-map-width: 65px;
$icon-map-height: 65px;
$icon-map-total-width: 221px;
$icon-map-total-height: 191px;
$icon-map-image: '../img/sprite.png';
$icon-map: (71px, 0px, -71px, 0px, 65px, 65px, 221px, 191px, '../img/sprite.png', 'icon-map', );
$icon-reuse-name: 'icon-reuse';
$icon-reuse-x: 0px;
$icon-reuse-y: 0px;
$icon-reuse-offset-x: 0px;
$icon-reuse-offset-y: 0px;
$icon-reuse-width: 67px;
$icon-reuse-height: 65px;
$icon-reuse-total-width: 221px;
$icon-reuse-total-height: 191px;
$icon-reuse-image: '../img/sprite.png';
$icon-reuse: (0px, 0px, 0px, 0px, 67px, 65px, 221px, 191px, '../img/sprite.png', 'icon-reuse', );
$icon-search-name: 'icon-search';
$icon-search-x: 201px;
$icon-search-y: 48px;
$icon-search-offset-x: -201px;
$icon-search-offset-y: -48px;
$icon-search-width: 18px;
$icon-search-height: 18px;
$icon-search-total-width: 221px;
$icon-search-total-height: 191px;
$icon-search-image: '../img/sprite.png';
$icon-search: (201px, 48px, -201px, -48px, 18px, 18px, 221px, 191px, '../img/sprite.png', 'icon-search', );
$icon-speed-name: 'icon-speed';
$icon-speed-x: 96px;
$icon-speed-y: 69px;
$icon-speed-offset-x: -96px;
$icon-speed-offset-y: -69px;
$icon-speed-width: 32px;
$icon-speed-height: 32px;
$icon-speed-total-width: 221px;
$icon-speed-total-height: 191px;
$icon-speed-image: '../img/sprite.png';
$icon-speed: (96px, 69px, -96px, -69px, 32px, 32px, 221px, 191px, '../img/sprite.png', 'icon-speed', );
$icon-sun-name: 'icon-sun';
$icon-sun-x: 140px;
$icon-sun-y: 69px;
$icon-sun-offset-x: -140px;
$icon-sun-offset-y: -69px;
$icon-sun-width: 33px;
$icon-sun-height: 33px;
$icon-sun-total-width: 221px;
$icon-sun-total-height: 191px;
$icon-sun-image: '../img/sprite.png';
$icon-sun: (140px, 69px, -140px, -69px, 33px, 33px, 221px, 191px, '../img/sprite.png', 'icon-sun', );
$icon-twitter-inverse-name: 'icon-twitter-inverse';
$icon-twitter-inverse-x: 201px;
$icon-twitter-inverse-y: 24px;
$icon-twitter-inverse-offset-x: -201px;
$icon-twitter-inverse-offset-y: -24px;
$icon-twitter-inverse-width: 17px;
$icon-twitter-inverse-height: 20px;
$icon-twitter-inverse-total-width: 221px;
$icon-twitter-inverse-total-height: 191px;
$icon-twitter-inverse-image: '../img/sprite.png';
$icon-twitter-inverse: (201px, 24px, -201px, -24px, 17px, 20px, 221px, 191px, '../img/sprite.png', 'icon-twitter-inverse', );
$icon-user-name: 'icon-user';
$icon-user-x: 140px;
$icon-user-y: 0px;
$icon-user-offset-x: -140px;
$icon-user-offset-y: 0px;
$icon-user-width: 57px;
$icon-user-height: 65px;
$icon-user-total-width: 221px;
$icon-user-total-height: 191px;
$icon-user-image: '../img/sprite.png';
$icon-user: (140px, 0px, -140px, 0px, 57px, 65px, 221px, 191px, '../img/sprite.png', 'icon-user', );
$spritesheet-width: 221px;
$spritesheet-height: 191px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon-arrow-down-lg-inverse, $icon-arrow-down, $icon-arrow-left-inverse, $icon-arrow-left-lg-inverse, $icon-arrow-left, $icon-arrow-right-inverse, $icon-arrow-right-lg-inverse, $icon-arrow-right-sm-primary, $icon-arrow-right-sm, $icon-arrow-right, $icon-arrow-up-inverse, $icon-close-inverse, $icon-close, $icon-download-inverse, $icon-energy, $icon-facebook-inverse, $icon-fan, $icon-gplus-inverse, $icon-linkedin-inverse, $icon-logo, $icon-map, $icon-reuse, $icon-search, $icon-speed, $icon-sun, $icon-twitter-inverse, $icon-user, );
$spritesheet: (221px, 191px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
