.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &_row {
        margin-left:  ($grid-gutter-width / -2);
        margin-right: ($grid-gutter-width / -2);
    }
}
.swiper-container-no-flexbox .swiper-slide {
    float: left;
}
.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}

/* ARROWS */
.swiper-carousel__btn-prev,
.swiper-carousel__btn-next {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -44px;
    z-index: 10;
    cursor: pointer;
    background: transparent;
    border: 1px solid #cbcbcb;
    padding: 6px 5px;
    text-align: center;
    display: none;
    transition: background .2s, border .2s;

    &::after {
        content: '';
        display: inline-block;
    }

    @include hover-focus {
        border-color: $gray-dark;
        background: $gray-dark;
    }


    @media (min-width: 1280px) {
        display: block;
    }

    &.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
}

.swiper-carousel__btn-prev {
    left: -18px;
    right: auto;

    @media (min-width: 1440px) {
        left: -50px;
    }

    &::after {
        @include sprite($icon-arrow-left);
    }

    @include hover-focus {
        &::after {
            @include sprite($icon-arrow-left-inverse);
        }
    }
}

.swiper-carousel__btn-next {
    right: -18px;
    left: auto;

    @media (min-width: 1440px) {
        right: -50px;
    }

    &::after {
        @include sprite($icon-arrow-right);
    }

    @include hover-focus {
        &::after {
            @include sprite($icon-arrow-right-inverse);
        }
    }
}


/* PAGINATION */
.swiper-carousel__pagination {
    position: relative;
    text-align: center;
    margin: 40px 0;
    transition: 300ms;
    transform: translate3d(0, 0, 0);
    z-index: 10;

    &.swiper-pagination-hidden {
        opacity: 0;
    }
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: transparent;
    border: 1px solid #cbcbcb;
    cursor: pointer;
    margin: 0 7px;

    transition: background .25s;
}

.swiper-pagination-bullet-active {
    background: #cbcbcb;
}
/* END PAGINATION */


/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}


.swiper-carousel {
    position: relative;


    .swiper-slide {
        .card:not(.card_gallery) {margin: 0 20px;}
    }
}



.swiper-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &__container {
        height: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: $light;
        box-shadow: 0 1px 1px rgba(0,0,0,.16);

        .swiper-slide {
            box-sizing: border-box;
            padding-bottom: 150px;

            @media #{$bp-mobile} {
                padding-bottom: 0;

                & > [data-swiper-parallax] {
                    transform: translate3d(0, 0, 0) !important;
                }
            }
        }

        @include hover-focus {
            .swiper-cover__btn-prev,
            .swiper-cover__btn-next {
                opacity: .5;

                @include hover-focus {
                    opacity: .9;
                }
            }
        }
    }


    &__thumbs {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        box-sizing: border-box;
        padding: 0;

        @media #{$bp-desktop} {
            display: flex;
        }

        .swiper-slide {
            flex: 1 0 auto;
            opacity: 1;
            width: auto;
            transition: opacity .25s linear;
            cursor: pointer;
            overflow: hidden;

            &__bg {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                transition: transform 1.2s ease-out;
            }

            &_active {
                opacity: 1;

                .swiper-slide__bg {
                    opacity: 0;
                }

                .card-hover {
                    opacity: 1;

                    &__title {
                        transform: scale(1);
                    }
                }
            }

            @include hover-focus {
                opacity: 1;

                .swiper-slide__bg {
                    transform: scale(1.16);
                }
            }
        }
    }


    &__btn-prev,
    &__btn-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
        opacity: 0;
        display: inline-block;
        transition: opacity .25s;

        @media #{$bp-desktop} {
            margin-top: -70px;
        }

        &.swiper-button-disabled {
            opacity: 0;
            cursor: auto;
            pointer-events: none;
        }
    }


    &__btn-prev {
        left: 2.3%;
        right: auto;
        @include spriteRatio($icon-arrow-left-lg-inverse,2);

        @media #{$bp-desktop} {
            @include spriteRatio($icon-arrow-left-lg-inverse,1);
        }
    }

    &__btn-next {
        right: 2.3%;
        left: auto;
        @include spriteRatio($icon-arrow-right-lg-inverse,2);

        @media #{$bp-desktop} {
            @include spriteRatio($icon-arrow-right-lg-inverse,1);
        }
    }

}



