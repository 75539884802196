/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  margin-left: auto;
  margin-right: auto;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  width: 100%;
  max-width: $container-max-widths;

  @include clearfix;
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-left:  ($gutter / -4);
  margin-right: ($gutter / -4);

  @media #{$bp-desktop} {
    margin-left:  ($gutter / -2);
    margin-right: ($gutter / -2);
  }
}

@mixin make-col($size, $columns: $grid-columns, $gutter: $grid-gutter-width) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-grid-columns($modifier:'', $columns: $grid-columns, $gutter: $grid-gutter-width) {
  @for $i from 1 through $columns {
    .grid-col#{$modifier}_#{$i} {
      @include make-col($i, $columns, $gutter);
    }
  }

  @for $i from 1 through $columns {
    .grid-col#{$modifier}-offset_#{$i} {
      @include make-col-offset($i, $columns)
    }
  }
}

