.footer {
    position: relative;
    background: $gray-dark;
    color: $light;
    padding: 60px 0 60px;
    text-align: center;

    @include clearfix;

    @media #{$bp-desktop} {
        text-align: left;
        padding: 24px 0 21px;
    }

    a {
        color: $light;
    }

    .footer-btn {
        display: inline-block;
        border: 1px solid #676767;
        text-align: center;
        width: 28px;
        height: 28px;
        padding: 3px 3px 0;
        margin: -6px 1px -4px;
        transition: border .25s;

        @include hover-focus {
            border-color: $light;
        }

        @media #{$bp-mobile} {
            padding: 7px 3px 0;
            margin: 0 8px;
            width: 36px;
            height: 36px;
        }
    }

    &-col {
        @media #{$bp-mobile} {
            &_1 {
                order: 1;
                margin-bottom: 15px;
            }
            &_2 {
                order: 2;
                margin-bottom: 15px;
            }
            &_3 {
                order: 3;
                margin-bottom: 5px;
            }
            &_4 {
                order: 4;
                margin-bottom: 5px;
            }
        }
    }
}