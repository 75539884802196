
.btn {
    display: inline-block;
    font: 500 14px/1 $font-family-heading;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    background: $brand-primary;
    color: $light;
    text-transform: uppercase;
    padding: 13px 20px 13px;
    transition: background .25s;

    @include hover-focus {
        background: $brand-primary-hover;
        color: $light;
        text-decoration: none;
    }

    @media #{$bp-desktop} {
        font-size: 16px;
        padding: 13px 40px 13px;
    }

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: 0;
        }
    }

    &_lg {
        padding: 13px 20px 13px;

        @media #{$bp-desktop} {
            padding: 19px 40px 18px;
        }
    }
}

.btn_small {
    display: inline-block;
    font: 500 12px/1 $font-family-heading;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    background: $brand-primary;
    color: $light;
    text-transform: uppercase;
    padding: 13px 10px 13px;
    transition: background .25s;

    @include hover-focus {
        background: $brand-primary-hover;
        color: $light;
        text-decoration: none;
    }

    @media #{$bp-desktop} {
        font-size: 12px;
        padding: 13px 20px 13px;
    }

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            outline: 0;
        }
    }

    &_lg {
        padding: 13px 10px 13px;

        @media #{$bp-desktop} {
            padding: 19px 20px 18px;
        }
    }
}

.btn-toolbar {
    text-align: center;
}

.btn-block {
    display: block;
    width: 100%;
}

.with_space {
    @media #{$bp-desktop} {
        margin-left: 55px;
    }

    @media #{$bp-phablet} {
        margin:0;
    }

    @media #{$bp-mobile} {
        margin:20px 0 0 0;
    }
}

.btn-scrollup {
    position: fixed;

    right: 20px;
    width: 26px;
    height: 32px;
    padding: 0;
    background: rgba(37,37,37,.7);
    border: 0;
    z-index: 2000;
    bottom: -37px;
    transition: bottom .25s, background .25s;

    @include hover-focus {
        background: rgba(37,37,37,.9);
    }

    &.affix {
        bottom: 30px;
    }

    @media #{$bp-desktop} {
        right: 40px;
        width: 30px;
        height: 37px;
    }
}


.read_more { text-align: center;}

.btn-scrolldown {
    position: absolute;
    bottom: 9%;
    z-index: 99;
    left: 50%;
    padding: 0;
    margin: 0 0 0 -41px;
    background: none;
    border: 0;
    opacity: .6;
    transition: opacity .25s linear;

    animation: scrolldown 4s infinite ease-in-out;

    @media #{$bp-desktop} {
        .swiper-cover & {
            margin-bottom: 150px;
        }
    }


    & > span {
        margin: 20px;
    }

    @include hover-focus {
        opacity: 1;
    }
}


@keyframes scrolldown {
    from {
        transform: translateY(0);
    }
    50% {
        transform: translateY(30px);
    }
    to {
        transform: translateY(0);
    }
}