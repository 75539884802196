.grid {
    &-page {
        position: relative;
        background: $gray-light;
        padding: 30px 0;

        @media #{$bp-desktop} {
            padding: 60px 0;
        }

        &_even {
            background: $light;
        }

        &_inverse {
            background: #323232;
            color: $light;
        }

        &_full {
            display: flex;
            min-height: calc(100vh - 62px);
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media #{$bp-desktop} {
                min-height: calc(100vh - 67px);
                //min-height: 500px;
            }
        }

        &_stick {
            padding: 0;

            &-sm {
                @media #{$bp-mobile} {
                    padding: 0;
                }
            }
        }

        &_cover {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh - 62px);
            margin-top: 62px;
            padding: 0;


            &-ghost {
                position: relative;
                height: calc(100vh - 62px);
            }

            @media #{$bp-desktop} {
                height: calc(100vh - 67px);
                margin-top: 67px;

                &-ghost {
                    height: calc(100vh - 67px);
                }
            }
        }

        &-header {
            position: relative;
            width: 100%;
            background: $light;
            padding: 20px 0;

            @media #{$bp-desktop} {
                padding: 50px 0;
            }

            &__aside {
                text-align: right;
                padding: 10px 0;

                @media #{$bp-desktop} {
                    align-self: center;
                }
            }
        }

        &__inner {
            position: relative;
            width: 100%;
        }

        &__title {
            text-align: center;
            margin-bottom: 30px;

            @media #{$bp-desktop} {
                margin-bottom: 54px;
            }
        }

        &-header &__title {
            margin-bottom: 0;
        }

        &__subtitle {
            text-align: center;
            color: $text-muted;
            font-weight: 100;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            max-width: 900px;

            @media #{$bp-desktop} {
                margin-bottom: 60px;
            }
        }

        .navbar + &,
        .navbar + &-header {
            margin-top: 62px;

            @media #{$bp-desktop} {
                margin-top: 67px;
            }
        }
    }

    &-container {
        @include make-container();
    }

    &-row {
        @include make-row();

        &_stick {
            margin-left: 0;
            margin-right: 0;
        }

        @media #{$bp-mobile} {
            &_gallery {
                margin-top: -30px;
                margin-left: -($grid-gutter-width / 2);
                margin-right: -($grid-gutter-width / 2);
            }

            &_sm-scroll {
                flex-wrap: nowrap;
                overflow-x: scroll;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    &-col {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        min-height: 1px;
        padding-right: ($grid-gutter-width / 4);
        padding-left:  ($grid-gutter-width / 4);

        @media #{$bp-desktop} {
            padding-right: ($grid-gutter-width / 2);
            padding-left:  ($grid-gutter-width / 2);
        }
    }

    &-hr {
        margin: 30px 0;

        @media #{$bp-desktop} {
            margin: 60px 0;
        }
    }
}


@include make-grid-columns();

@media #{$bp-mobile} {
    @include make-grid-columns('-sm');
}

@media #{$bp-desktop} {
    @include make-grid-columns('-lg');
}

.touchevents .grid-page_cover {
    position: absolute;
}
