$bp-mobile          : "screen and (max-width: 768px)";
$bp-phablet         : "screen and (min-width: 600px) and (max-width: 959px)";
$bp-desktop         : "screen and (min-width: 769px)";


$gray-dark:                #252525 !default;
$gray:                     #55595c !default;
$gray-light:               #f4f4f4 !default;
$light:                    #fff !default;

$text-muted:               #a2a5a9 !default;
$separator-color:          #eee !default;

$brand-primary:            #0D9CD0 !default;
$brand-primary-hover:      #1FA7D9 !default;
$brand-info:               #5bc0de !default;
$brand-warning:            #d9534f !default;

$body-bg:    #fff !default;
$body-color: $gray-dark !default;

$link-color:            $brand-primary !default;
$link-hover-color:      $brand-primary-hover !default;

$font-family-sans-serif: "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-heading:    "Segoe WP", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-root: 16px !default;
$font-size-base: 14px !default;
$line-height-base: 1.357 !default;

$font-size-h1: 40px !default;
$font-size-h2: 30px !default;
$font-size-h3: 26px !default;
$font-size-h4: 22px !default;
$font-size-h5: 18px !default;
$font-size-h6: 16px !default;

$font-size-h1-sm: 24px !default;
$font-size-h2-sm: 20px !default;
$font-size-h3-sm: 20px !default;
$font-size-h4-sm: 18px !default;
$font-size-h5-sm: 17px !default;
$font-size-h6-sm: 16px !default;

$small-font-size: 12px !default;


$headings-margin-bottom:  20px !default;
$headings-font-family:    $font-family-heading !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.4 !default;
$headings-line-height-sm: 1.3 !default;
$headings-color:          inherit !default;


$grid-columns:      12 !default;
$grid-gutter-width: 40px !default;
$container-max-widths: $grid-gutter-width + 1340px;

$spacer:   20px !default;
$spacer-x: 20px !default;
$spacer-y: 20px !default;