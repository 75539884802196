.nav-menu {
    padding: 20px 0;
    margin: 0;
    list-style: none;
    order: 2;
    background: $light;
    overflow: hidden;

    @media #{$bp-desktop} {
        display: flex;
        justify-content: center;
        order: 1;
        flex: 1 0 auto;
        flex-direction: row;
        width: 60%;
        padding: 0;
    }

    &__item {
        position: relative;
        flex: 0 0 auto;
        display: block;
        margin: 0 20px;
        transition: height .25s, border .25s;
        height: 43px;

        @media #{$bp-desktop} {
            position: static;
            margin: 0;
            height: auto;
        }

        & + & {
            border-top: 1px solid #eaeaea;

            @media #{$bp-desktop} {
                border-top: 0;
            }
        }

        &_dropdown {
            height: auto !important;

            @media #{$bp-desktop} {
                padding-bottom: 19px;
            }
        }

        &.collapse-in & {
            &-link::after {
                transform: rotate(180deg);
            }
        }

        &.collapse-in {
            border-color: $light;
        }

        @media #{$bp-mobile} {
            .parent-collapse-in > &:not(.collapse-in) {
                height: 0 !important;
                overflow: hidden;
                border-color: $light;
            }
        }

        &-link {
            position: relative;
            display: block;
            padding: 13px 0;
            background: $light;
            color: $gray-dark;
            text-transform: uppercase;
            font: 700 16px/1 $font-family-heading;
            transition: color .25s;

            @include hover-focus {
                color: $brand-primary;
            }

            @media #{$bp-desktop} {
                font-size: 14px;
                padding: 8px 17px;
                margin: 0;
            }


            &::after {
                content: '';
                float: right;
                display: inline-block;
                @include sprite($icon-arrow-right);
                transition-duration: .35s;
                transition-property: transform;

                @media #{$bp-desktop} {
                    display: none;
                }
            }
        }

        &.active &,
        &_active & {
            &-link {
                color: $text-muted;
            }
        }
    }

    &-dropdown {
        position: relative;
        overflow-x: hidden;

        @media #{$bp-desktop} {
            position: absolute;
        }

        &__container {
            position: relative;
            left: 100%;
            transition-duration: .35s;
            transition-property: left;

            @media #{$bp-desktop} {
                left: 0;
            }
        }

        &.in &__container {
            left: 0;
        }
    }
}

.nav-submenu {
    padding: 0 0 20px;
    margin: 0;
    list-style: none;

    @media #{$bp-desktop} {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 840px;
        box-sizing: content-box;
    }

    &__item {
        flex: 0 0 auto;
        display: block;
        margin: 0;
        width: 90%;

        @media #{$bp-desktop} {
            width: 50%;
        }

        &-link {
            position: relative;
            display: block;
            padding: 10px 0;
            color: $gray-dark;
            font: 400 14px/1 $font-family-base;
            transition: color .25s;

            &::before {
                float: left;
                display: inline-block;
                margin: 3px 5px 0 0;
                @include sprite($icon-arrow-right-sm);

                @media #{$bp-desktop} {
                    content: '';
                }
            }

            @include hover-focus {
                &::before {
                    color: $brand-primary;
                    @include sprite($icon-arrow-right-sm-primary);
                }
            }

        }
    }
}
