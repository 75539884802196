.nav-lang {
    order: 1;
    flex: 0 0 auto;
    position: relative;
    padding: 4px 0;
    font: 500 16px/1 $font-family-heading;

    @media #{$bp-desktop} {
        font-size: 14px;
        order: 2;
        padding: 0 25px 0 0;
        margin: 0 0 19px;
        border-right: 1px solid $separator-color;
    }

    &__toggle {
        background: none;
        border: 0;
        padding: 10px 12px 10px 20px;
        color: $text-muted;

        @media #{$bp-desktop} {
            padding: 8px 0 8px 16px;

            &::after {
                color: #d9d9d9;
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 8px;
                margin-bottom: 2px;
                vertical-align: middle;
                content: "";
                border-top: 5px solid;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
        }
    }

    &.open > &__toggle {
        &::after {
            border-top: 0;
            border-bottom: 5px solid;
        }
    }

    &-dropdown {
        display: inline-block;
        padding: 10px 0;
        margin: 0 auto;
        list-style: none;

        @media #{$bp-desktop} {
            display: none;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            background: $gray-dark;
            color: $light;
            width: 60px;
            text-align: center;

            &::after {
                position: absolute;
                bottom: 100%;
                left: 0;
                right: 0;
                color: $gray-dark;
                display: block;
                width: 0;
                height: 0;
                margin: 0 auto;
                content: "";
                border-bottom: 8px solid;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }

        &__item {
            padding: 0;
            display: inline-block;

            @media #{$bp-desktop} {
                display: block;
            }

            &-link {
                display: inline-block;
                color: $gray-dark;
                text-transform: uppercase;
                padding: 10px 10px;
                transition: color .25s;

                @include hover-focus {
                    color: $brand-primary;
                }

                @media #{$bp-desktop} {
                    display: block;
                    color: $light;
                    padding: 10px 0;

                    @include hover-focus {
                        color: $brand-primary;
                    }

                }
            }
        }
    }

    &.open > &-dropdown {
        display: block;
    }
}
