.form {

    &__group {
        margin-bottom: 10px;

        sup {
            font-size: 100%;
            top: 0;
        }
    }

    &__control {
        display: block;
        width: 100%;
        padding: 7px 14px;
        font-size: 14px;
        line-height: 20px;
        color: #252525;
        background-color: $light;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid #e7e7e7;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        &::placeholder {
            color: #a2a5a9;
            opacity: 1;
        }

        &:focus {
            border-color: $brand-primary;
            box-shadow: none;
            outline: none;
        }

        &_sm {
            padding: 12px;
            font-size: 12px;
        }

        &_lg {
            padding: 16px;
            font-size: 16px;
        }
    }

    &__select {
        display: block;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 14px;
            pointer-events: none;
            display: inline-block;
            @include sprite($icon-arrow-down);

            @media #{$bp-desktop} {
                right: 15px;
            }
        }

        &-control {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            border: 1px solid #e7e7e7;
            border-radius: 0;
            display: block;
            width: 100%;
            padding: 7px 24px 7px 10px;
            font-size: 16px;
            color: $text-muted;
            font-family: $font-family-heading;
            font-weight: 500;
            background: $light;
            line-height: 20px;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

            @media #{$bp-desktop} {
                padding: 7px 20px 7px 20px;
            }

            &:focus {
                border-color: $brand-primary;
                box-shadow: none;
                outline: none;
            }
        }
    }

}

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-bottom: 10px;

    label {
        padding-left: 1.25rem;
        margin-bottom: 0;
        cursor: pointer;

        input:only-child {
            position: static;
        }
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-top: .25rem;
    margin-left: -1.25rem;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -.25rem;
}

.radio-inline,
.checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 1.25rem;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: .75rem;
}