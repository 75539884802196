.spacing {
    margin-bottom: 20px;

    &_xs {
        margin-bottom: 10px;
    }

    &_sm {
        margin-bottom: 16px;
    }

    &_lg {
        margin-bottom: 40px;
    }

    &-all {
        margin: 20px;
    }

    &-tp {
        margin-top: 20px;

        &_sm {
            margin-top: 16px;
        }

        &_lg {
            margin-top: 40px;
        }
    }

    &-bt {
        margin-bottom: 20px;

        &_sm {
            margin-bottom: 16px;
        }

        &_lg {
            margin-bottom: 40px;
        }
    }

    &-y {
        margin-top: 20px;
        margin-bottom: 20px;

        &_sm {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        &_lg {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    &-x {
        margin-left: 20px;
        margin-right: 20px;

        &_sm {
            margin-left: 16px;
            margin-right: 16px;
        }

        &_lg {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
}

.stick {
    &-sm {
        @media #{$bp-mobile} {
            padding: 0;
            margin: 0;
        }
    }
}

.overview-text {
    @media #{$bp-desktop} {
        padding-top: 100px;
    }
}