.icon {

    &-reuse {
        display: inline-block;
        @include sprite($icon-reuse);
    }
    &-map {
        display: inline-block;
        @include sprite($icon-map);
    }
    &-user {
        display: inline-block;
        @include sprite($icon-user);
    }

    &-facebook-inverse {
        display: inline-block;
        @include sprite($icon-facebook-inverse);
    }
    &-gplus-inverse {
        display: inline-block;
        @include sprite($icon-gplus-inverse);
    }
    &-twitter-inverse {
        display: inline-block;
        @include sprite($icon-twitter-inverse);
    }
    &-linkedin-inverse {
        display: inline-block;
        @include sprite($icon-linkedin-inverse);
    }

    &-arrow-up-inverse {
        display: inline-block;
        @include sprite($icon-arrow-up-inverse);
    }

    &-arrow-down-lg-inverse {
        display: inline-block;
        @include sprite($icon-arrow-down-lg-inverse);
    }

    &-arrow-left-inverse {
        display: inline-block;
        @include sprite($icon-arrow-left-inverse);
    }

    &-arrow-right-inverse {
        display: inline-block;
        @include sprite($icon-arrow-right-inverse);
    }

    &-arrow-left {
        display: inline-block;
        @include sprite($icon-arrow-left);
    }

    &-arrow-right {
        display: inline-block;
        @include sprite($icon-arrow-right);
    }

    &-download-inverse {
        display: inline-block;
        margin-top: -2px;
        @include sprite($icon-download-inverse);
    }

    &-sun {
        display: inline-block;
        @include sprite($icon-sun);
    }
    &-speed {
        display: inline-block;
        @include sprite($icon-speed);
    }
    &-fan {
        display: inline-block;
        @include sprite($icon-fan);
    }
    &-energy {
        display: inline-block;
        @include sprite($icon-energy);
    }

}

.wrapper-icon {
    display: inline-flex;
    border-radius: 50%;
    width: 63px;
    height: 63px;
    background: $light;
    text-align: center;
    justify-content: center;
    align-items: center;
}