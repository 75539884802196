html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}


@at-root {
    @-ms-viewport { width: device-width; }
}



html {
    font-size: $font-size-root;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $body-color;
    background-color: $body-bg;
}
html,
body {
    margin:0;
    padding:0;
}


[tabindex="-1"]:focus {
    outline: none !important;
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}


ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

a {
    color: $link-color;
    text-decoration: none;

    @include hover-focus {
        color: $link-hover-color;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
}


figure {
    margin: 0 0 1rem;
}


img {
    vertical-align: middle;
}

[role="button"] {
    cursor: pointer;
}


a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}



label {
    display: inline-block;
    margin-bottom: .5rem;
}

button:focus {
    outline: none;
}

input,
button,
select,
textarea {
    margin: 0;
    line-height: inherit;
    border-radius: 0;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

input[type="search"] {
    -webkit-appearance: none;
}

[hidden] {
    display: none !important;
}

img {max-width:100%;}