.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacer;

    th,
    td {
        padding: 10px;
        vertical-align: top;
        border-top: 1px solid #ebebeb;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 1px solid #ebebeb;
    }

    tbody + tbody {
        border-top: 1px solid #ebebeb;
    }

    .table {
        background-color: $body-bg;
    }
}

.table-container {
    position: relative;
    margin: 20px auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @media #{$bp-desktop} {
        margin-top: 40px;
    }

    &__scroll {
        position: relative;
        display: block;
        overflow-y: scroll;
        overflow-x: visible;
        min-height: .01%;
        max-height: 200px;
        width: 100%;
        min-width: 600px;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar{
            width: 12px;
            height: 12px;
        }
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-corner{
            //background: #D4D4D4;
            border: 1px solid #D4D4D4;
        }
        &::-webkit-scrollbar-thumb{
            background: #D4D4D4;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb:hover{
            background: #D4D4D4;
        }
    }

    &__fixed {
        position: relative;
        width: 100%;
        min-width: 600px;
        overflow-y: scroll;
        background: #323232;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar{
            width: 12px;
            height: 12px;
        }

        &-top {
            position: relative;
            z-index: 99;
        }
    }
}


.table-product {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0;

    th,
    td {
        padding: 8px 8px;
        vertical-align: top;
        border-top: 1px solid #ebebeb;
        text-align: left;
        width: 16.66%;
        max-width: 16.66%;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
            border-top-color: #fff;
        }

        @media #{$bp-desktop} {
            padding: 10px 20px;
        }
    }

    thead th {
        vertical-align: bottom;
        background: #323232;
        color: #fff;
        border-top: 0;
        border-bottom: 1px solid #fff;
    }

    &_fixed thead th {
        border-bottom: 0;
    }


    tbody th {
        &.red {
            background: #ff4f02;
            color: #fff;
        }

        &.blue {
            background: #369CD0;
            color: #fff;
        }
    }

    tbody + tbody {
        border-top: 1px solid #ebebeb;
    }

    tbody tr:nth-of-type(even) {
        background-color: #f4f4f4;
    }

    .table {
        background-color: $body-bg;
    }
}
