.card {
    position: relative;
    display: block;
    background-color: $light;
    color: inherit;
    transition: color .25s;

    &_unstyled {
        background-color: transparent;
    }

    &_bordered {
        border: 1px solid $separator-color;
    }

    &_gallery {
        margin-left: 0;
        margin-right: 0;

        .grid-col > & {
            margin-left: -10px;
            margin-right: -10px;

            @media #{$bp-desktop} {
                margin-left: -20px;
                margin-right: -20px;
            }
        }
    }

    &_block,
    &__block {
        padding: 20px;
    }

    &__link {
        color: inherit;
        transition: color .25s;
    }

    &__title {
        display: block;
        padding: 15px 5px;
        margin: 0;
        font-family: $font-family-heading;
        font-weight: 500;

        &_uppercase {
            text-transform: uppercase;
        }
    }

    &__image {
        display: block;
        position: relative;
        overflow: hidden;

        & > img {
            transition: all 1.2s ease-out;
            display: block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }

        &_flex {
            display: flex;
            width: 100%;
            height: 180px;
            justify-content: center;
            align-items: center;

            & > img {
                width: auto;
            }
        }
    }

    &__address {
        display: block;
        padding: 0;
        margin: 0 0 10px;
        font-style: normal;

        @media #{$bp-desktop} {
            padding-right: 20px;
        }
    }

    &__modal-content {
        display: none;

        @media #{$bp-desktop} {
            display: block;
        }
    }

    &__modal-toggle {
        display: block;
        cursor: pointer;

        @media #{$bp-desktop} {
            display: none;
        }
    }


    @include hover-focus {
        color: inherit;

        .card {
            &-hover {
                opacity: 1;
            }

            &__link {
                color: $brand-primary;
            }

            &__image > img {
                transform: scale(1.16);
            }

        }
    }
}


.card-hover {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0,112,153,.45);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .25s linear;

    &__title {
        color: $light;
        font-weight: 100;
        margin: 0;
        padding: 10px;
        text-transform: none;
        font-family: $font-family-heading;
        transform: scale(0);
        transition: all .15s linear;
    }

    @include hover-focus {
        opacity: 1;

        .card-hover__title {
            transform: scale(1);
        }
    }
}