.media {
    display: flex;
    margin-bottom: $spacer;

    @media #{$bp-mobile} {
        text-align: center;
        flex-direction: column;
    }

    &__body {
        flex: 1;
    }

    &__addon-right {
        @media #{$bp-mobile} {
            padding-bottom: 10px;
        }

        @media #{$bp-desktop} {
            padding-left: 20px;
        }
    }

    &__addon-left {
        @media #{$bp-mobile} {
            padding-bottom: 10px;
        }

        @media #{$bp-desktop} {
            padding-right: 20px;
        }
    }

    &__object {
        display: block;
    }

}

.media__middle {
    align-self: center;
}
.media__bottom {
    align-self: flex-end;
}


