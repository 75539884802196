.phone_block {
    position: relative;
    order: 3;
    flex: 0 0 auto;
    margin: 0;
    text-align: center;
    padding: 6px 10px 10px 20px;
    font-weight: 700;
    color:#1FA7D9;
}

.search {
    position: relative;
    order: 4;
    flex: 0 0 auto;
    margin: 0;

    @media #{$bp-desktop} {
        position: static;
    }

    &-toggle {
        border: 0;
        padding: 7px 24px 5px 25px;
        background: none;
        display: none;

        @media #{$bp-desktop} {
            display: block;
        }

        &__icon {
            display: block;
            @include sprite($icon-search);
        }
    }

    &-dropdown {
        position: relative;
        display: block;
        background: transparent;

        @media #{$bp-desktop} {
            position: absolute;
            display: none;
            background: rgba(235,234,234,.93);
        }
    }

    &.open > &-dropdown {
        display: block;
    }

    &-form {
        position: relative;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;

        @include make-container();

        @media #{$bp-desktop} {
            margin-top: 40px;
            margin-bottom: 40px;
            display: flex;
            justify-content: flex-end;
        }

        &__control {
            padding: 11px 14px;

            @media #{$bp-desktop} {
                flex: 0 1 500px;
                border-color: transparent;
            }
        }

        &__btn {
            border: 0;
            padding: 0;
            background: none;
            margin: 0 0 0 15px;
            font: 500 14px/1 $font-family-heading;
            text-indent: -9999px;

            &::after {
                content: '';
                position: absolute;
                top: 13px;
                right: 29px;
                display: block;
                @include sprite($icon-search);
            }

            @media #{$bp-desktop} {
                border: 1px solid $brand-primary;
                color: $brand-primary;
                padding: 10px;
                flex: 0 0 auto;
                text-indent: 0;
                transition: color .25s, background .25s;

                @include hover-focus {
                    color: $light;
                    background: $brand-primary;
                }

                &::after {
                    display: none;
                }
            }

        }
    }
}