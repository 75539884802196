
// Inline turns list items into inline-block
.list-inline {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}


.list-group {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        padding: 0;
        border-color: $separator-color;
        border-bottom: 1px solid;

        &:first-child {
            border-top: 1px solid;
        }

        &-link {
            display: block;
            padding: 22px 0;
            transition: color .25s;

            @include hover-focus {
                color: $brand-primary;
            }
        }
    }

    &_inverse & {
        &__item {
            border-color: #4d4d4d;

            &-link {
                color: $light;

                @include hover-focus {
                    color: $brand-primary;
                }
            }
        }
    }
}

.list-downloads {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        padding: 0;
        border-bottom: 1px solid #4d4d4d;

        &:first-child {
            border-top: 1px solid #4d4d4d;
        }

        &-link {
            color: $light;
            display: flex;
            padding: 22px 0;
            transition: color .25s;

            @include hover-focus {
                color: $brand-primary;
            }
        }

        &-title {
            flex: 1 1 auto;
            align-self: center;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            text-transform: uppercase;
        }

        &-icon {
            flex: 0 0 auto;
            align-self: center;
        }
    }
}