.subnavbar {
    position: static;
    top: 0;
    right: 0;
    left: 0;
    display: none;
    overflow: hidden ;
    z-index: 9990;
    height: 41px;
    margin-bottom: -41px;
    background: $gray-dark;
    box-shadow: 0 1px 1px rgba(0,0,0,.07);
    padding: 0;

    @media #{$bp-desktop} {
        display: block;
    }

    @include clearfix;

    &.affix-top {
        position: absolute;
    }

    &.affix {
        position: fixed;
        top: 0 !important;
        right: 0;
        left: 0;
        z-index: 9991;
    }

    &-ghost {
        display: none;
        height: 41px;
        background: $gray-dark;

        @media #{$bp-desktop} {
            display: block;
        }
    }
}


.touchevents .subnavbar {

    &.affix-top {
        position: static;
    }

    &.affix {
        position: static;
        z-index: 1;
    }
}

.subnav-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    order: 1;
    flex: 1 0 auto;
    flex-direction: row;

    &__item {
        position: relative;
        flex: 0 0 auto;
        display: block;
        margin: 0;

        &-link {
            position: relative;
            display: block;
            padding: 11px 15px 12px;
            color: $text-muted;
        }

        &.active &,
        &_active & {
            &-link {
                color: $light;
            }
        }
    }
}


.subnavbar-filter {
    position: absolute;
    top: 21px;
    left: 50%;
    z-index: 9992;
    display: block;
    width: 90px;
    margin-left: -40px;

    @media #{$bp-desktop} {
        display: none;
    }

    &::after {
        position: absolute;
        top: 9px;
        right: 4px;
        color: $light;
        display: block;
        width: 0;
        height: 0;
        margin: 0 auto;
        content: "";
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    &__control {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid $gray-dark;
        border-radius: 0;
        display: block;
        width: 100%;
        padding: 2px 14px 2px 5px;
        font-size: 12px;
        color: $light;
        font-family: $font-family-heading;
        font-weight: 500;
        background: $gray-dark;
        line-height: 14px;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        &:focus {
            border-color: $brand-primary;
            box-shadow: none;
            outline: none;
        }
    }
}