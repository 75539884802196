
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin hover-focus {
    &:focus,
    &:hover {
      @content
    }
}



@mixin spriteRatio($sprite, $ratio:1) {
  @include sprite-image($sprite);
  width: nth($sprite, 5)/$ratio;
  height: nth($sprite, 6)/$ratio;
  $sprite-offset-x: nth($sprite, 3)/$ratio;
  $sprite-offset-y: nth($sprite, 4)/$ratio;
  $sprite-width: nth($sprite, 7)/$ratio;
  $sprite-height: nth($sprite, 8)/$ratio;
  background-size: $sprite-width  $sprite-height;
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@import "./_grid";
