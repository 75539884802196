.article {
    background: $light;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 -20px;

    @media #{$bp-desktop} {
        display: block;
        margin: 0;
    }

    &__media {
        order: 1;

        @media #{$bp-desktop} {
            width: 66.66667%;
        }
    }

    &__content {
        order: 3;
        padding: 20px;

        @media #{$bp-desktop} {
            width: 66.66667%;
            padding: 50px 30px;
        }
    }

    &__header {
        order: 2;
        padding: 20px;
        color: $text-muted;

        @media #{$bp-desktop} {
            float: right;
            width: 33.33333%;
            padding: 40px;
        }
    }
}