.map {
    padding: 0;

    &__header {
        position: relative;
        width: 100%;
        padding: 40px 0 0;

        @media #{$bp-desktop} {
            padding: 80px 0 20px;
        }
    }

    &__content {
        position: relative;
        flex: 1 0 auto;
        min-height: 380px;
    }

    &-container {
        position: relative;
    }

    &-card {
        min-width: 300px;
        z-index: 10;
        text-align: center;
        margin: -30px -20px 0;

        @media #{$bp-desktop} {
            position: absolute;
            top: 60px;
            left: 20px;
            right: 20px;
            margin: 0;
            text-align: left;
        }

        &__title {
            @media #{$bp-mobile} {
                margin-bottom: 10px;
            }
        }

        &__hr {
            @media #{$bp-mobile} {
                border-color: transparent;
                margin: 0;
            }
        }

        &__btn-toolbar {
            text-align: center;

            @media #{$bp-mobile} {
                padding-top: $spacer;
            }
        }
    }

    &-embed {
        position: absolute;
        top: 168px;
        bottom: 0;
        left: 0;
        right: 0;
        background: $gray-light;
        min-height: 240px;

        @media #{$bp-desktop} {
            top: 0;
        }
    }
}