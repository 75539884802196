@font-face {
    font-family: "Segoe WP";
    src: url("../fonts/segoewp-light.ttf")  format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe WP';
    src: url("../fonts/segoewp-regular.ttf")  format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe WP';
    src: url("../fonts/segoewp-semibold.ttf")  format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe WP';
    src: url("../fonts/segoewp-bold.ttf")  format("truetype");
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: "Lato";
    src: url("../fonts/lato-regular.eot");
    src: url("../fonts/lato-regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/lato-regular.woff2") format("woff2"),
        url("../fonts/lato-regular.woff") format("woff"),
        url("../fonts/lato-regular.ttf")  format("truetype");
    font-weight: normal;
    font-style: normal;
}

