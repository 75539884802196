.search-result {
    padding: 0;
    margin: 0;

    @media #{$bp-desktop} {
        background: $light;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        display: block;
        padding: 20px 20px;
        margin: 0 0 30px;
        border-bottom: 1px solid #f4f4f4;
        background: $light;

        @media #{$bp-desktop} {
            padding: 50px 30px;
            margin: 0;
        }
    }

    &__btn-more {
        padding: 20px 0;
        text-align: center;

        @media #{$bp-desktop} {
            padding: 60px 0;
        }
    }

    &-card {
        display: flex;
        color: inherit;

        &__image {
            margin-right: 30px;
            flex: 1 1 100px;
            max-width: 100px;
            overflow: hidden;
            display: none;

            & > img {
                transition: all .4s ease-out;
                display: block;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }

            @media #{$bp-desktop} {
                display: block;
            }
        }

        &__link {
            transition: color .25s;
        }

        &__block {
            flex: 1 1 auto;
            align-self: center;

            p {
                margin-bottom: 0;
            }
        }

        @include hover-focus {
            color: inherit;

            .search-result-card {
                &__link {
                    color: $brand-primary;
                }

                &__image > img {
                    transform: scale(1.16);
                }

            }
        }
    }
}