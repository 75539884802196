.cover {
    width: 100%;
    height: 100%;

    text-align: center;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__title {
        text-shadow: 0 1px 2px rgba(0,0,0,.25);
        color: $light;
        padding-left: 20px;
        padding-right: 20px;
    }
}