.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9991;
    height: 62px;
    transform: translate3d(0, 0, 0);
    background: $light;
    box-shadow: 0 1px 1px rgba(0,0,0,.07);
    padding: 18px 0 19px;

    &.affix-top {
        top: 0 !important;
    }

    &.affix {
        position: absolute;
        top: 500px;
    }

    @include clearfix;

    @media #{$bp-desktop} {
        height: 67px;
    }

    &-header {

        @include clearfix;

        @media #{$bp-desktop} {
            float: left;
        }

        &__brand {
            float: left;
            display: block;
            text-indent: -9999px;

            @media #{$bp-mobile} {
                @include spriteRatio($icon-logo, 1.28);
            }

            @media #{$bp-desktop} {
                @include spriteRatio($icon-logo, 1);
            }
        }
    }

    &-toggle {
        position: absolute;
        right: 0;
        top: 0;
        padding: 30px 20px;
        background: transparent;
        border: 0;
        z-index: 9999;

        @media #{$bp-desktop} {
            display: none;
        }

        &__icon {
            position: relative;
            width: 26px;
            height: 3px;
            display: block;
            background: transparent;
            border: 0;
            transition: .5s;

            &::before,
            &::after {
                content: "";
                width: 26px;
                height: 3px;
                background: $gray-dark;
                position: absolute;
                left: 0;
                z-index: 1;
                top: 0;
                transition: .5s;
            }
            &::before {
                transform: rotate(-45deg);
            }
            &::after {
                transform: rotate(45deg);
            }
        }

        &.collapsed &__icon {
            background: $gray-dark;

            &::before,
            &::after {
                transform:rotate(0deg);
                transition: .5s;
            }

            &::before {
                top: -8px;
            }
            &::after {
                top: 8px;
            }
        }
    }

    &-collapse {
        z-index: 9993;
        background: $gray-light;
        display: flex;
        width: 100%;
        height: 100vh!important;
        position: absolute;
        top: 0;
        left: 100%;
        overflow-x: visible;
        -webkit-overflow-scrolling: touch;
        flex-direction: column;
        transition-timing-function: ease;
        transition-duration: .25s;
        transition-property: left,visibility;

        @media #{$bp-desktop} {
            background: $light;
            position: static;
            display: flex;
            flex-direction: row;
            height: auto!important;
            padding-bottom: 0;
            overflow: visible!important;
            width: auto;
            left: auto;
        }

        &.in {
            display: flex;
            overflow-y: auto;
            left: 0%;

            @media #{$bp-desktop} {
                overflow-y: visible;
            }
        }

        &.collapsing {
            display: flex;
            left: 0;
            overflow: hidden;
        }
    }

    &-dropdown {
        display: none;

        @media #{$bp-desktop} {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: rgba(235,234,234,.93);
        }

        &.in {
            display: block;
        }

        &.collapsing {
            display: block;
            overflow: hidden;
            height: 0;
        }

        &__container {
            @include make-container();
        }
    }
}

.touchevents .navbar {
    top: 0 !important;

    &.affix {
        position: fixed;
        top: 0;
    }
}