.product-ordering {
    text-align: center;
    margin: 40px 0;

    &_hidden {
        display: none;
    }
}


.fjc{
    @media #{$bp-desktop} {
        justify-content:center;
    }
}

.grid-col_2.type {
    flex: 0 0 19.957%;
    max-width: 19.957%;
}

.p0 {
    padding:0px;
}
.cl {
    display: block;
    float:none;
    clear: right;
}
.grid-col_2.consumption {
    flex: 0 0 15.8%;
    max-width: 15.8%;
}

.card__image .img-65 {
    width: 50%;
}

.card__image .img-80 {
    width: 55%;

}

.card__image .img-105 {
    width: 60%;
}

.card__image .img-125 {
   width: 70%;
}

.card__image .img-165 {
    width: 80%;
}

.card__image .img-205 {
    width: 100%;
}