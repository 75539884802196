
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height-sm;
    color: $headings-color;

    @media #{$bp-desktop} {
        line-height: $headings-line-height;
    }

    &_stick {
        margin-bottom: 0;
    }
}

h1, .h1 {
    font-size: $font-size-h1-sm;
    text-transform: uppercase;

    @media #{$bp-desktop} {
        font-size: $font-size-h1;
    }
}


h2, .h2 {
    font-size: $font-size-h2-sm;
    text-transform: uppercase;

    @media #{$bp-desktop} {
        font-size: $font-size-h2;
    }
}
h3, .h3 {
    font-size: $font-size-h3-sm;
    text-transform: uppercase;

    @media #{$bp-desktop} {
        font-size: $font-size-h3;
    }
}
h4, .h4 {
    font-size: $font-size-h4-sm;

    @media #{$bp-desktop} {
        font-size: $font-size-h4;
    }
}
h5, .h5 {
    font-size: $font-size-h5-sm;

    @media #{$bp-desktop} {
        font-size: $font-size-h5;
    }
}
h6, .h6 {
    font-size: $font-size-h6-sm;

    @media #{$bp-desktop} {
        font-size: $font-size-h6;
    }
}

.text-heading {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
}

small,
.small {
    font-size: $small-font-size;
    font-weight: normal;
}


hr {
    margin-top: $spacer-y;
    margin-bottom: $spacer-y;
    border: 0;
    border-top: 1px solid $separator-color;
}

.link_unstyled {
    color: inherit;
    padding-left: 0;
    list-style: none;
    transition: color .25s;
}


.text-light {font-weight: 100;}

.text-justify        { text-align: justify !important; }
.text-nowrap         { white-space: nowrap !important; }
//.text-truncate       { @include text-truncate; }

.text-lowercase      { text-transform: lowercase !important; }
.text-uppercase      { text-transform: uppercase !important; }
.text-capitalize     { text-transform: capitalize !important; }
.text-untransform    { text-transform: none !important; }

.text-primary { color: $brand-primary !important;}
.text-info { color: $brand-info !important;}
.text-muted { color: $text-muted !important;}
.text-dark { color: $gray-dark !important;}
.text-error { color: $brand-warning !important;}


.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}


.text-left   {
    text-align: left !important;

    @media #{$bp-mobile} {
        &-sm {
            text-align: left !important;
        }
    }

}
.text-right  {
    text-align: right !important;

    @media #{$bp-mobile} {
        &-sm {
            text-align: right !important;
        }
    }
}
.text-center {
    text-align: center !important;

    @media #{$bp-mobile} {
        &-sm {
            text-align: center !important;
        }
    }
}

.text-top { vertical-align: top !important; }

.divider {
    border-top: 2px solid;
    display: block;
    width: 36px;
    margin: 0 0 20px;
}


.hidden-sm {
    @media #{$bp-mobile} {
        display: none  !important;
    }
}

.visible-sm {
    display: none;
    
    @media #{$bp-mobile} {
        display: block  !important;
        flex: 0 0 100%;
    }
}

.pull-left {
    float: left;
}

.pull-right {
     float: right;
 }